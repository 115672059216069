import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LayoutsComponent} from './layouts/layouts.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './login/auth.guard';
import {RegisterComponent} from './login/register.component';
import {GlobalService} from './global';
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
    {path:'',
    component:LayoutsComponent,
    canActivate: [AuthGuard],
    children:
    [
      {
        path: 'user',
        loadChildren: './user/user.module#UserModule',
        data: {
          breadcrumb: 'user'
        }
    
      },
      {
        path: 'my',
        loadChildren: './my/my.module#MyModule'
      }    
  ]},
  


];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { enableTracing: true } )],
  exports: [RouterModule],
  providers:[GlobalService]
})
export class AppRoutingModule {

 }
