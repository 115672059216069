import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {LoginService} from './login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd';

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [LoginService]
})
export class RegisterComponent implements OnInit {

  validateForm: FormGroup;
  errMsg: string;
  canClick=false;
  description = "获取验证码";
  t = 0;

  _submitForm() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[ i ].markAsDirty();
    }
  }

  constructor(private fb: FormBuilder, private loginService: LoginService, private router: Router,
              private _message: NzMessageService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      email            : [ null, [ Validators.email, Validators.required ] ],
      password         : [ null, [ Validators.required ] ],
      checkPassword    : [ null, [ Validators.required, this.confirmationValidator ] ],
      captcha          : [ null, [ Validators.required ] ],
      agree            : [ false ]
    });
  }
  getCaptcha(): void {
    console.log("111");
    const email = this.validateForm.value.email;
    if (null == email){
      this._message.error("请输入邮箱");
      return;
    }
    this.loginService.captcha(email).subscribe((result) => {
      console.log(result);
      if (result.errMsg) {
        this.errMsg = result.errMsg;
        return;
      }
      if ("ok" == result.status) {
        this.canClick = true;
        const email = this.validateForm.value.email;
        this.description = "60秒后重发"
        this.t =60;
        setTimeout(_ =>{
          this.setT();
        }, 1000); 
      }
      else{
        this._message.error(result.data);
      }
    });

  
  }
  setT(){
    if (this.t <= 0){
      this.description = "重新发送";
    }
    else{
    this.t = this.t - 1;
    this.description = new String(this.t) + "秒后重发";
    setTimeout(_ =>{
      this.setT();
    }, 1000); 
    }
  }
  confirmationValidator = (control: FormControl): { [ s: string ]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
  };
  register() {
    const email = this.validateForm.value.email;
    const password = this.validateForm.value.password;
    const captcha = this.validateForm.value.captcha;
    console.log("222");
    this.loginService.register(email, password, captcha).subscribe((result) => {
      console.log(result);
      if (result.errMsg) {
        this.errMsg = result.errMsg;
        return;
      }
      if ("ok" == result.status) {
        this.router.navigate(['/login']);
      }
      else{
        this._message.error(result.data);
      }
    });
  }

}
