import {Inject, Component } from '@angular/core';
import {Menu} from './menu';
import {GlobalService} from './global';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [GlobalService]
})
export class AppComponent {
  title = 'app';

  constructor(
    @Inject(GlobalService)
    private globalService:GlobalService) {

  }

  _originMenuList: Array<Menu> = [
    {
      name: '题库管理',
      subMenu: [
        {
          name: '培训',
          path: '/user'
        },
        {
          name: '我的页面',
          path: '/question/batch'
        }
      ]
    }


  ];

  menuList: Array<Menu> = [];

  ngOnInit(): void {


    // Disable the copy and cut


    // this.menuService.getAuthority().subscribe((data) => {
    //   console.log(data);
    //   // for (const menu of this._originMenuList) {
    //   //    this.menuList.push(menu);
    //   // }
    //   for (const menu of this._originMenuList) {
    //     this.menuList.push(menu);
    //   }
    //     });
      for (const menu of this._originMenuList) {
        this.menuList.push(menu);
      }

  }

  logout() {

  }

}
