import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HashLocationStrategy , LocationStrategy} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import {HttpModule} from '@angular/http';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import{LayoutsComponent} from './layouts/layouts.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './login/register.component';
import {BreadcrumbComponent } from './layouts/breadcrumb.component';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard} from './login/auth.guard';
import {AuthInterceptor} from './AuthInterceptor';
registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    LayoutsComponent,
    LoginComponent,
    RegisterComponent,
    BreadcrumbComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgZorroAntdModule.forRoot(),
    FormsModule,
    HttpClientModule,
    HttpModule,
    ReactiveFormsModule
  ],
  providers: [{
     provide: NZ_I18N, useValue: zh_CN },
     {provide: LocationStrategy, useClass: HashLocationStrategy},
     { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
     AuthGuard,
     FormBuilder ],
  bootstrap: [AppComponent]
})
export class AppModule { }
