import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { LoginService } from "./login.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd";
import * as url from "url";
import * as querystring from "querystring";
import { GlobalService, Global } from "../global";
import { Md5 } from "ts-md5";
@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [LoginService],
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;
  errMsg: string;
  isVisible = false;
  validateFormModify: FormGroup;
  canClick = false;
  description = "获取验证码";
  t = 0;
  userId: string;
  text: String;

  _submitModifyForm() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
    }
  }

  _submitForm() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
    }
  }

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private _message: NzMessageService,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams && queryParams["sId"] && queryParams["openId"]) {
        const userName = queryParams["sId"];
        const password = queryParams["openId"];
        this._login(userName, password);
      }
    });
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true],
    });
    this.validateFormModify = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      agree: [false],
    });
    this.loginService.queryInform().subscribe((data) => {
      if (null == data) {
        this.text = "安心做题";
      } else {
        this.text = data.text;
      }
    });
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (
      control.value !== this.validateFormModify.controls.password.value
    ) {
      return { confirm: true, error: true };
    }
  };

  handleCancel(): void {
    this.isVisible = false;
  }
  getCaptcha(): void {
    console.log("111");
    const email = this.validateFormModify.value.email;
    if (email.search("gmail") != -1) {
      this._message.error("谷歌邮箱可能暂时发送不到，请更换邮箱");
      return;
    }
    if (null == email) {
      this._message.error("请输入邮箱");
      return;
    }
    this.loginService.captcha(email).subscribe((result) => {
      console.log(result);
      if (result.errMsg) {
        this.errMsg = result.errMsg;
        return;
      }
      if ("ok" == result.status) {
        this.canClick = true;
        const email = this.validateFormModify.value.email;
        this.description = "60秒后重发";
        this.t = 60;
        setTimeout((_) => {
          this.setT();
        }, 1000);
      } else {
        this._message.error(result.data);
      }
    });
  }
  setT() {
    if (this.t <= 0) {
      this.description = "重新发送";
    } else {
      this.t = this.t - 1;
      this.description = new String(this.t) + "秒后重发";
      setTimeout((_) => {
        this.setT();
      }, 1000);
    }
  }
  _login(username, password) {
    const checkedPassword = (Md5.hashStr(username) as string)
      .split("")
      .reverse()
      .join("");
    if (checkedPassword !== password) {
      this._message.error("用户名密码错误，请重新登录");
      return;
    }
    const realPassword = username.split("").reverse().join("");
    this.loginService.login(username, realPassword).subscribe((result) => {
      console.log(result);
      if ("ok" == result.status) {
        document.cookie =
          "token=" + result.data.token + ";userId=" + result.data.userId;
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("userId", result.data.userId);
        localStorage.setItem("userName", result.data.userName);
        this.globalService.setUserId(result.data.userId);
        this.globalService.setUserName(result.data.userName);
        this.router.navigate(["/user"]);
      } else {
        this._message.error(result.status);
      }
    });
  }
  login() {
    const username = this.validateForm.value.userName;
    const password = this.validateForm.value.password;
    this.loginService.login(username, password).subscribe((result) => {
      console.log(result);
      if ("no_email" == result.status) {
        this.userId = result.data.userId;
        this.isVisible = true;
      } else if ("ok" == result.status) {
        document.cookie =
          "token=" + result.data.token + ";userId=" + result.data.userId;
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("userId", result.data.userId);
        localStorage.setItem("userName", result.data.userName);
        this.globalService.setUserId(result.data.userId);
        this.globalService.setUserName(result.data.userName);
        this.activatedRoute.queryParams.subscribe((queryParams) => {
          if (queryParams && queryParams["returnUrl"]) {
            const returnUrl = url.parse(queryParams["returnUrl"]);
            if (returnUrl.query) {
              const qs = querystring.parse(returnUrl.query);
              this.router.navigate([returnUrl["pathname"]], {
                queryParams: qs,
              });
            } else {
              this.router.navigate([returnUrl["pathname"]]);
            }
          } else {
            this.router.navigate(["/user"]);
          }
        });
      } else {
        this._message.error(result.status);
      }
    });
  }
  handleOk() {
    const email = this.validateFormModify.value.email;
    const password = this.validateFormModify.value.password;
    const captcha = "";

    this.loginService
      .updateFirst(email, password, captcha, this.userId)
      .subscribe((result) => {
        console.log(result);
        if ("ok" == result.status) {
          document.cookie =
            "token=" + result.data.token + ";userId=" + result.data.userId;
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("userId", result.data.userId);
          localStorage.setItem("userName", result.data.userName);
          this.globalService.setUserId(result.data.userId);
          this.globalService.setUserName(result.data.userName);
          this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (queryParams && queryParams["returnUrl"]) {
              const returnUrl = url.parse(queryParams["returnUrl"]);
              if (returnUrl.query) {
                const qs = querystring.parse(returnUrl.query);
                this.router.navigate([returnUrl["pathname"]], {
                  queryParams: qs,
                });
              } else {
                this.router.navigate([returnUrl["pathname"]]);
              }
            } else {
              this.router.navigate(["/user"]);
            }
          });
        } else {
          this._message.error(result.status);
        }
      });
  }
}
