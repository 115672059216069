import { Injectable } from '@angular/core';
import {  Response } from '@angular/http';

import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import * as util from 'util';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
@Injectable()
export class TrainService {

  constructor(private http: HttpClient) {
  }

  queryInform(): Observable<any> {
    const path = util.format('/api/login/inform');

    return this.http.get(path).pipe(map((res: Response) => {
      return res;
    }));
  }
  
}