

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private http: Http, ) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    var token = localStorage.getItem("token");
    var userId = localStorage.getItem("userId");
    return this.http.get('/api/login/login?token=' + token + '&userId=' + userId).pipe(map((res) => {
      const result = res.json();
      if ("ok" == result.status) {
        return true;
      }
      console.log('navigate to login');
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return false;

    }));
  }
}
