import { Inject,Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from "@angular/router";

import { filter } from 'rxjs/operators';
import{GlobalService, Global} from '../global';

interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}

@Component({
  selector: "breadcrumb",
  template: `
      <div *ngFor="let breadcrumb of breadcrumbs"  nz-col [nzSpan]="2">
        <a [routerLink]="[breadcrumb.url]"  [queryParams]="breadcrumb.params">{{ breadcrumb.label }}</a>
        </div>

  `
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: IBreadcrumb[];

  /**
   * @class DetailComponent
   * @constructor
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService : GlobalService
  ) {
    this.breadcrumbs = [];
  }

  /**
   * Let's go!
   *
   * @class DetailComponent
   * @method ngOnInit
   */
  ngOnInit() {
    const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";

    //subscribe to the NavigationEnd event

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      //set breadcrumbs
      let root: ActivatedRoute = this.activatedRoute.root;
      event = <NavigationEnd>event;
      this.breadcrumbs = this.getBreadcrumbs(root, event.url);
      
    });
  }

  /**
   * Returns array of IBreadcrumb objects that represent the breadcrumb
   *
   * @class DetailComponent
   * @method getBreadcrumbs
   * @param {ActivateRoute} route
   * @param {string} url
   * @param {IBreadcrumb[]} breadcrumbs
   */
  private getBreadcrumbs(route: ActivatedRoute, url: string=""): IBreadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";
    var breadcrumbs: IBreadcrumb[]=[]
    //get the child routes
    let children: ActivatedRoute[] = route.children;
    // if (url.indexOf('user') != -1){
    //     console.log("!!!");
    //     breadcrumbs = this.addValue('user', breadcrumbs);
    //     breadcrumbs = this.addValue('subject', breadcrumbs);
    //     breadcrumbs= this.addValue('exam'), breadcrumbs;
    // }

            var breadcrumb1 =this.addValue('user', breadcrumbs)
            if (null != breadcrumb1){
                breadcrumbs.push(breadcrumb1)
            }
            var breadcrumb2 =this.addValue('subject', breadcrumbs)
            if (null != breadcrumb2){
                breadcrumbs.push(breadcrumb2)
            }
            var breadcrumb3 =this.addValue('exam', breadcrumbs)
            if (null != breadcrumb3){
                breadcrumbs.push(breadcrumb3)
            }
            var breadcrumb4 =this.addValue('realexam', breadcrumbs)
            if (null != breadcrumb4){
                breadcrumbs.push(breadcrumb4)
            }
            var breadcrumb5 =this.addValue('video', breadcrumbs)
            if (null != breadcrumb5){
                breadcrumbs.push(breadcrumb5)
            }
    //    breadcrumbs = this.addValue('user', breadcrumbs);
    //     breadcrumbs = this.addValue('subject', breadcrumbs);
    //     breadcrumbs= this.addValue('exam', breadcrumbs);

    return breadcrumbs;
  }
  private addValue( url: string, breadcrumbs: IBreadcrumb[]): IBreadcrumb {

        var data = this.globalService.getValue(url);
        
        if (null == data){
            return null;
        }

        let breadcrumb: IBreadcrumb = {
            label: data.label,
            params: data.params,
            url: data.url
        };
        return breadcrumb;
    }
}       