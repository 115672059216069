import { Injectable } from '@angular/core';
import {  Response } from '@angular/http';

import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import * as util from 'util';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';

@Injectable()
export class LoginService {

  static LOGIN_PATH = '/api/login/login';
  static LOGOUT_PATH = '/api/v1/session';


  constructor(private http: HttpClient) {
  }

  login(username: string, password: string): Observable<any> {
    const body = {
      email: username,
      password: password
    };

    return this.http.post(LoginService.LOGIN_PATH, body).pipe(map((res) => {
      return res;
    }));

  }

  updateFirst(email: string, password: string, captcha: string, userId: string): Observable<any> {
    const body = {
      email: email,
      password: password,
      captcha: captcha,
      userId : userId
    };

    return this.http.post("/api/login/update/first", body).pipe(map((res) => {
      return res;
    }));

  }

  register(email: string, password: string, captcha: string): Observable<any> {
    const body = {
      email: email,
      password: password,
      captcha: captcha
    };

    return this.http.post("/api/login/register", body).pipe(map((res) => {
      return res;
    }));

  }
  queryInform(): Observable<any> {
    const path = util.format('/api/login/inform');

    return this.http.get(path).pipe(map((res: Response) => {
      return res;
    }));
  }
  resetPassword(email: string, password: string, captcha: string): Observable<any> {
    const body = {
      email: email,
      password: password,
      captcha: captcha,
      userId: localStorage.getItem('userId')
    };

    return this.http.post("/api/login/reset", body).pipe(map((res) => {
      return res;
    }));

  }

  captcha(username: string): Observable<any> {
    const body = {
      email: username
    };

    return this.http.post("/api/login/captcha", body).pipe(map((res) => {
      return res;
    }));

  }

  
  captchaPass(username: string): Observable<any> {
    const body = {
      email: username
    };

    return this.http.post("/api/login/captcha/pass", body).pipe(map((res) => {
      return res;
    }));

  }


  queryEmail(): Observable<any> {
    const path = util.format('/api/user/email?userId=' + localStorage.getItem("userId") 
    );

    return this.http.get(path).pipe(map((res: Response) => {
      return res;
    }));

  }
  logout(): Observable<void> {
    return this.http.delete(LoginService.LOGOUT_PATH).pipe(map((res) => {
      return;
    }));
  }

}
