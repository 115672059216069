import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { Observable } from 'rxjs';
import {  catchError, tap, map } from "rxjs/operators";
import { Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    spinner: any;


    constructor(
        private router: Router) {
    
      }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var token = null !=  localStorage.getItem("token") ? localStorage.getItem("token") : '';
        var userId = null !=  localStorage.getItem("userId") ? localStorage.getItem("userId") : '';

        var locale = null !=  localStorage.getItem("locale") ? localStorage.getItem("locale") : '';
        const clonedRequest = req.clone({
            headers: req.headers.set('token', token).set('locale', locale).set("userId", userId)
        });
        clonedRequest.headers.keys()
        console.log(clonedRequest);
        return next.handle(clonedRequest).pipe(
            catchError((error, ca) => {
                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 401:
                            return this.router.navigate(['/login']);
                        default:
                            return this.router.navigate(['/login']);
                    }
                } else {
         
                }
            }),
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                }
                return event;
            }));
    }


}
