import { Component } from '@angular/core';
import {Menu} from './menu';
import {Router} from '@angular/router';
import{GlobalService, Global} from '../global';
import { NzModalService,NzFormatEmitEvent } from 'ng-zorro-antd';
import {TrainService} from './layouts.service';
@Component({
  selector: 'app-root',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss'],
  providers: [TrainService]
})
export class LayoutsComponent {
  title = 'app';
  userId :String;
  isCollapsed = false;
  isOpen = true;
  isReverseArrow = false;
  isBig=true;
  text : String;

   isFt= false;
   ftText='切换到繁体';

  constructor(private router: Router, private globalService : GlobalService,private modalService: NzModalService, private trainService : TrainService) {
  }

  _originMenuList: Array<Menu> = [
    {
      name: '题库',
      subMenu: [
        {
          name: '培训查看',
          path: '/user'
        }
      ]
    },
    {
      name: '我的',
      subMenu: [
        {
          name: '我的考试记录',
          path: '/my/exam'
        },
        {
          name: '我的错题',
          path: '/my/error'
        },
        {
          name: '我的收藏',
          path: '/my/love'
        },
        {
          name: '账户管理',
          path: '/my/account'
        },
        {
          name: '我要留言',
          path: '/my/message'
        }
      ]
    }


  ];

  menuList: Array<Menu> = [];

  ngOnInit(): void {

    var locale = localStorage.getItem('locale');
    this.isFt = 'ft' == locale;
    this.ftText = this.isFt ? '切换到简体':'切换到繁体';
    this.isBig = window.innerWidth > 700;
      this.userId = localStorage.getItem('userId');
      this.trainService.queryInform().subscribe(
        (data) =>{
            if (null == data){
              this.text ="安心做题";
            }
            else{
              this.text = data.text;
            }
            setTimeout(_ =>{
              this.fetch();
            }, 10000); 
        }
      );
    // this.menuService.getAuthority().subscribe((data) => {
    //   console.log(data);
    //   // for (const menu of this._originMenuList) {
    //   //    this.menuList.push(menu);
    //   // }
    //   for (const menu of this._originMenuList) {
    //     this.menuList.push(menu);
    //   }
    //     });
      for (const menu of this._originMenuList) {
        this.menuList.push(menu);
      }

  }
  clickSwitch(){
    this.isFt = !this.isFt;
    this.ftText = this.isFt ? '切换到简体':'切换到繁体';
    var locale = this.isFt ? 'ft' : 'ch';
    
    console.log(locale);
    localStorage.setItem('locale', locale);
    window.location.reload();
  }
  fetch(){
    this.trainService.queryInform().subscribe(
      (data) =>{
          if (null == data){
            this.text ="安心做题";
          }
          else{
            this.text = data.text;
          }
          setTimeout(_ =>{
            this.fetch();
          }, 1000000); 
      }
    );
  }

  logout() {
    this.modalService.confirm({
      nzTitle: '注销确认',
      nzContent: '你确定要注销吗，注销后需要重新登录',
      nzOnOk: () => {
        localStorage.setItem(
          "token", null);
          this.router.navigate(['/login']);
      }
    });

  }

}
